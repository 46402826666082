import { db } from '@/firebase/config'

const getAllReports = async (store) => {
  try {
		store.value = [];
    await db.collection("Reports").get().then(docs => {
      docs.forEach(doc => {
				store.value.push({...doc.data(), reportId: doc.id});
			});
    });
    // console.log("getAllReports activated.")
  } catch (error) {
    alert("getAllReports: " + error);
  }

};

export default getAllReports
