import { db } from '@/firebase/config'


const getCollectedEmails = async (store) => {
  try {
    store.value = [];
    await db.collection("EmailList").get().then(docs => {
      docs.forEach(doc => {
        store.value.push({ address: doc.data().address, id: doc.id });
      })
    })
    //console.log("getCollectedEmails activated")
  } catch (error) {
    alert("getCollectedEmails " + error)
  }

};

export default getCollectedEmails
