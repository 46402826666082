import { db } from '@/firebase/config'


const sendPromotion = async (useremail) => {
  try {
    await db.collection("mail").add({
      to: useremail,
      template: {
        name: 'Promotion2',
      }
    })
    console.log("sendPromotion activated.")
  } catch (error) {
    alert("sendPromotion: " + error)
  }

};

export default sendPromotion
