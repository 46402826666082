import { db } from '@/firebase/config'

const temp = async () => {
  try {
    let promises = []
    await db.collection('Profiles').get().then((docs)=>{
      docs.forEach(doc => {
        if (doc.exists){
          promises.push(doc.ref.collection("myUnreadMsgs").doc("unreadMsgs").set({unreadMsgs: 0}));
        }
      })
    })
    await Promise.all(promises)
    //.log("temp activated");
  } catch (error) {
    alert("temp: " + error);
  }
};

export default temp
